import axios from 'axios';
import keycloak from "../keycloak";
import {Resource} from "@rest-hooks/rest";

declare global {
    interface Window { _env_?: {SMARTBOT_API?: string}; }
}

export const smartbotApi = window._env_?.SMARTBOT_API || process.env.SMARTBOT_API || "http://localhost:8080";

export const client = axios.create({
    baseURL: smartbotApi
});

client.interceptors.request.use((async function (options) {
    console.log(keycloak);
    options.headers = {
        ...options.headers,
        Authorization: `Bearer ${keycloak.token}`,
    };
    return options;
}));

export abstract class ApiResource extends Resource {

    id?: string;

    static readonly apiPath: string = "";

    /** Perform network request and resolve with HTTP Response */
    static fetchResponse(
        input: RequestInfo,
        init: RequestInit,
    ): Promise<Response> {
        const options: RequestInit = {
            ...init,
            headers: {
                'Authorization': `Bearer ${keycloak.token}`,
                ...init.headers,
            },
        };
        return Resource.fetchResponse(input, options);
    }

    static get urlRoot() {
        return smartbotApi + this.apiPath;
    }

    protected static urlWithSubPath(path: string) {
        if(path.startsWith('/')) {
            path = path.substr(1);
        }
        let urlRoot = this.urlRoot;
        if (urlRoot.endsWith('/')) {
            return `${urlRoot}${path}`;
        }
        return `${urlRoot}/${path}`;

    }

    pk(parent?: any, key?: string): string | undefined {
        return this.id?.toString();
    }

}

export default client;
