import {Page} from "../../components";
import React, {Suspense, useCallback} from "react";
import {useNavigate, useParams} from "react-router";
import {Environment} from "../../api/Environment";
import {useFetcher, useResource, useSubscription} from "rest-hooks";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box,
    Button, ButtonGroup,
    Chip,
    Grid,
    LinearProgress, Link,
    Paper,
    Typography
} from "@mui/material";
import {Deployment, DeploymentLog} from "../../api/Deployment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {StopCircle} from "@mui/icons-material";

function DeploymentUI({deployment}: { deployment: Deployment }) {
    let logs = useResource(DeploymentLog.list(), {environment: deployment.environment, deployment: deployment.id});

    useSubscription(DeploymentLog.list(), deployment.state !== 'RUNNING' ? {
        environment: deployment.environment,
        deployment: deployment.id
    } : null);

    return <>
        <Paper sx={{
            backgroundColor: '#000',
            color: '#fff',
            fontFamily: 'ubuntu-mono',
            width: '100%',
            padding: 2
        }}>
            {logs
                .sort((d, de) => new Date(d.createdAt || "").getTime() - new Date(de.createdAt || "").getTime())
                .map((l) => <Grid container key={l.id}>
                    <Grid item xs={4}><Typography>[{l.createdAt}][{l.sourceType}][{l.sourceId}]</Typography></Grid>
                    <Grid item xs={8}><Typography>{l.content}</Typography></Grid>
                </Grid>)
            }
        </Paper>
    </>
}

function ApplicationsList({environment}: { environment: Environment }) {
    const applications = useResource(Environment.applications(), {id: environment.id});
    useSubscription(Environment.applications(), {id: environment.id});
    return <>
        {
            applications.map(app => {
                return (
                    <Box key={app.name}>
                        <Typography>{app.name}: <Link color="inherit" href={app.url} underline="hover">{app.url}</Link></Typography>

                    </Box>
                );
            })
        }
    </>;
}

function HaltDeployment({environment}: { environment: Environment }) {
    let halt = useFetcher(Environment.haltAnyPendingDeployment());

    if(environment.state === 'DEPLOYING') {
        return <Box paddingLeft={2} display="inline"><Button variant="contained" onClick={() => halt({id: environment.id})} startIcon={<StopCircle/>}><Typography>HALT</Typography></Button></Box>
    }
    return null;
}

function DeploymentsList(props: { environment: Environment }) {
    let deployments = useResource(Deployment.list(), {environment: props.environment.id});
    let stop = useFetcher(Environment.stop());
    let pause = useFetcher(Environment.pause());
    let start = useFetcher(Environment.start());
    let deleteEnv = useFetcher(Environment.delete());
    let navigate = useNavigate();

    const deleteEnvAndReloadHome = useCallback(() => {
        deleteEnv({id: props.environment.id})
            .finally(() => navigate('/'))
    }, [props.environment, deleteEnv, navigate])

    useSubscription(Deployment.list(), {environment: props.environment.id});

    return <>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Box marginY={2}>
                    <Typography variant="h5">Environment</Typography>
                    <Box marginY={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>Runtime</Grid>
                        <Grid item xs={8}>{props.environment.runtime}</Grid>
                        <Grid item xs={4}>Status</Grid>
                        <Grid item xs={8}>
                            {props.environment.state}
                            <HaltDeployment environment={props.environment}/>
                        </Grid>
                        <Grid item xs={4}>Applications</Grid>
                        <Grid item xs={8}><ApplicationsList environment={props.environment}/></Grid>
                    </Grid>
                    </Box>
                </Box>
                <Box marginY={2}>
                    <Typography variant="h5">Deployments</Typography>
                    <Box marginY={1}>
                        {
                            deployments.sort((d, de) => new Date(de.createdAt || "").getTime() - new Date(d.createdAt || "").getTime()).map(d =>
                                <Accordion key={d.id}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Deployment {new Date(d.createdAt || "").toLocaleString()}</Typography>
                                        <Chip size="small" label={d.state}/>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DeploymentUI deployment={d}/>
                                    </AccordionDetails>
                                </Accordion>)
                        }
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <ButtonGroup>
                    <Button variant={"outlined"}
                            onClick={() => start({id: props.environment.id})}>Start</Button>
                    <Button variant={"outlined"}
                            onClick={() => pause({id: props.environment.id})}>Pause</Button>
                    <Button variant={"outlined"}
                            onClick={() => stop({id: props.environment.id})}>Stop</Button>
                    <Button variant={"outlined"}
                            onClick={() => deleteEnvAndReloadHome()}>Delete</Button>
                </ButtonGroup>

            </Grid>
        </Grid>

    </>;
}

export const EnvironmentPage: React.FC = () => {
    const {id} = useParams<'id'>();
    const environment = useResource(Environment.detailPolling(), {id});
    useSubscription(Environment.detailPolling(), {id});

    return (
        <Page title={environment.name} breadcrumbs={[{name: 'Environments', path: '/environments'}]}>
            <Suspense fallback={<LinearProgress/>}>
                <DeploymentsList environment={environment}/>
            </Suspense>
        </Page>
    );
}
