import React, {Suspense} from "react"
import {Page, PageTitle} from "../../../components"
import {useParams} from "react-router";
import { useResource } from "rest-hooks";
import {Project} from "../../../api/Project";
import {Mission} from "../../../api/Mission";
import {Grid, Typography} from "@mui/material";
import { Paper } from "@mui/material";
import { Box } from "@mui/material";
import {Markdown} from "../../../components/Markdown";
import { Skeleton } from '@mui/material';
import { WorkspacesWidget } from "./WorkspaceWidget";

export const MissionPage: React.FC = () => {
    const params = useParams<'project' | 'mission'>();

    const [project, mission] = useResource(
        [Project.detail(), {id: params.project}],
        [Mission.detail(), {id: params.mission}],
    )

    return <Page title={mission.title} breadcrumbs={[
        {name: 'Projects', path: `/projects`},
        {name: project.title, path: `/projects/${project.id}`},
        {name: 'Missions', path: `/projects/${project.id}/missions`},
    ]}>
        <PageTitle>{mission.title}</PageTitle>
        <Box margin={2}>
            <Grid container spacing={2}>
                <Grid item md={8}>
                    <Paper>
                        <Box padding={2}>
                            <Markdown content={mission.description}/>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <Paper>
                        <Box padding={2}>
                            <Typography variant="h6">Workspaces</Typography>
                            <Suspense fallback={<Skeleton />}>
                                <WorkspacesWidget mission={mission}/>
                            </Suspense>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    </Page>
}
