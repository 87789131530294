import {Project} from "../api/Project";
import {Grid, Typography} from "@mui/material";

export function ProjectCard({project}: { project: Project }) {
    return <Grid container>
        <Grid item>
            <Typography>{project.title}</Typography>
        </Grid>
    </Grid>;
}
