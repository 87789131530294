import React, {MouseEvent, useCallback} from "react";
import {Project} from "../../../api/Project";
import {useResource} from "rest-hooks";
import {Mission} from "../../../api/Mission";
import {useNavigate} from "react-router";
import {Box, Checkbox, Link, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";

export const MissionsList: React.FC<{ project: Project }> = ({project}) => {
    let missions = useResource(Mission.list(), {projectId: project.id});
    const navigate = useNavigate();
    const openMission = useCallback((mission: Mission) => {
        navigate(`/projects/${mission.project}/missions/${mission.id}`);
    }, [navigate])

    return (
        <Box marginLeft={-3} marginRight={-3}>
            <List>
                {
                    missions.map((m) => <ListItem key={m.id}>
                        <ListItemSecondaryAction>
                            <Checkbox/>
                        </ListItemSecondaryAction>
                        <ListItemText primary={
                            <Link
                                color="textPrimary"
                                href={`/projects/${m.project}/missions/${m.id}`}
                                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                    e.preventDefault();
                                    openMission(m)
                                }}
                                underline="hover">
                                {m.title}
                            </Link>
                        }/>
                    </ListItem>)
                }
            </List>
        </Box>
    );
}
