import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Box from "@mui/material/Box";
import {CssBaseline} from "@mui/material";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FFD92B'
        },
        secondary: {
            main: '#282828'
        }
    },
    components: {

    },
});

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline/>
                <Box height="100vh" display="flex" flexDirection="column" className="ag-theme-alpine-dark">
                    <App/>
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
