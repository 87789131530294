import {ApiResource} from "./api";
import {Application} from "./Application";

class EnvironmentConfiguration {
    key?: string;
    value?: string;
}

export class Environment extends ApiResource {

    project?: string;
    name?: string;
    slug?: string;
    state?: string;
    configuration?: EnvironmentConfiguration[];
    runtime?: string;
    pendingDeployment?: any;
    latestDeployment?: any;

    static detailPolling() {
        return this.memo('#detailWithPolling', () => this.detail().extend({
            url: ({id}) => this.urlWithSubPath(id),
            pollFrequency: 2000
        }));
    }

    static applications() {
        return this.memo('#applications', () => this.detail().extend({
            url: ({id}) => this.urlWithSubPath(`${id}/applications`),
            pollFrequency: 2000,
            schema: [Application]
        }));

    }

    static apiPath = '/v2/environments';

    static stop() {
        return this.endpoint().extend(
            {
                url: (params) => `${this.url(params)}/stop`,
                method: 'POST'
            }
        );
    }

    static start() {
        return this.endpoint().extend(
            {
                url: (params) => `${this.url(params)}/start`,
                method: 'POST'
            }
        );
    }

    static pause() {
        return this.endpoint().extend(
            {
                url: (params) => `${this.url(params)}/pause`,
                method: 'POST'
            }
        );
    }

    static haltAnyPendingDeployment() {
        return this.endpoint().extend(
            {
                url: (params) => `${this.url(params)}/haltAnyPendingDeployment`,
                method: 'POST'
            }
        );
    }
}
