import {Autocomplete, AutocompleteProps, TextField} from "@mui/material";
import {User} from "../../api/User";
import React, {useMemo, useState} from "react";
import {useResource} from "rest-hooks";

export type UserSelectProps = Omit<AutocompleteProps<string | undefined, false, false, false>, 'options' | 'renderInput'> &
    Partial<Pick<AutocompleteProps<string, false, false, false>, 'options' | 'renderInput'>> & {
        filterUsersId?: (userId: string) => boolean
    };

export const UserSelect: React.FC<UserSelectProps> = (props) => {
    const [open, setOpen] = useState(false);
    const users = useResource(User.list(), {});
    const usersById = useMemo(() => {
        const list: {[id: string]: User} = {};
        users.forEach(u => list[u.id as string] = u);
        return list;
    }, [users]);
    const value = props.value ?? null;
    let options = useMemo(() => Object.keys(usersById).filter((id) => {
        if(props.filterUsersId) {
            return props.filterUsersId(id);
        } else {
            return true;
        }
    }), [props, usersById]);
    return  (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(id) => {
                if(id) {
                    let user = usersById[id];
                    return `${user.givenName} ${user.familyName}`;
                } else {
                    return ``;
                }
            }}
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="User"
                />
            )}
            {...props}
            value={value}
        />
    );
}
