import {ProjectRole} from "../../../api/ProjectMembership";
import {useContext} from "react";
import {Project, ProjectContext} from "../../../api/Project";
import {useResource} from "rest-hooks";
import React from 'react';
import {useCurrentUser} from "../../../hooks";

const grantedRolesMap: {[p in ProjectRole]: ProjectRole[]} = {
  [ProjectRole.OWNER]: [ProjectRole.OWNER, ProjectRole.DEVELOPER, ProjectRole.MEMBER],
  [ProjectRole.DEVELOPER]: [ProjectRole.DEVELOPER, ProjectRole.MEMBER],
  [ProjectRole.MEMBER]: [ProjectRole.MEMBER],
}

export const WithProjectLevelAccess: React.FC<{level: ProjectRole}> = ({level, children}) => {
  const project = useContext(ProjectContext);
  const {user} = useCurrentUser();
  const userId = user?.id;
  const memberships = useResource(Project.projectMembership(), {projectId: project.id});
  const validMemberships = memberships.filter((p) => p.userId === userId && p.level && grantedRolesMap[p.level]?.includes(level));
  if(validMemberships.length > 0) {
    return <>{children}</>;
  } else {
    return null;
  }
}
