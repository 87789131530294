import {Project} from "../../api/Project";
import {useController, useResource} from "rest-hooks";
import {useNavigate} from "react-router";
import React, {useCallback, useState} from "react";
import {Page, ProjectCard} from "../../components";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Provider} from "../../api/Provider";
import {useForm} from "react-hook-form";

function ImportProjectFromProvider({
                            onClose
                        }: { onClose: () => void }) {
    const {handleSubmit, watch, setValue} = useForm({
        defaultValues: {
            providerId: "",
            projectId: ""
        }
    });
    const {fetch} = useController();

    let handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const providers: Provider[] = useResource(Provider.list(), {});

    const onSubmit = useCallback(async (data) => {
        fetch(Project.importFromProvider(), {
            providerId: data.providerId,
            providerType: providers.find(provider => provider.id === data.providerId)!.type,
        }, {
            providerId: data.providerId,
            project: data.projectId,
        }).then(async () => {
            handleClose();
        })
    }, [fetch, handleClose, providers])


    return <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Import Project</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{marginTop: 1}}>
                    <InputLabel id="provider-select">Provider</InputLabel>
                    <Select labelId="provider-select"
                            label="Provider"
                            onChange={e => setValue('providerId', e?.target?.value, {})}
                            value={watch('providerId')}
                            required={true}
                    >
                        {
                            providers?.map(provider => <MenuItem key={provider.id} value={provider.id}>{provider.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{
                    marginTop: 2,
                }}>
                    <TextField
                        label={"Project Number"}
                        value={watch("projectId")}
                        onChange={e => setValue('projectId', e.target.value)}
                        required={true}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" autoFocus>
                    Import
                </Button>
            </DialogActions>
        </form>
    </Dialog>
}

export function ProjectListPage() {
    const projects: Project[] = useResource(Project.list(), {});
    const navigate = useNavigate();
    const [importProjectFromProvider, setImportProjectFromProvider] = useState(false);

    const openProject = useCallback((p: Project) => {
        navigate(`/projects/${p.id}`)
    }, [navigate]);

    return (
        <Page title={"Projects"}>
            <Grid container alignItems={"flex-end"} justifyContent={"flex-end"}>
                <Grid item>
                    <Button  variant="contained" color="primary" onClick={() => setImportProjectFromProvider(true)}>Import Project</Button>
                    {importProjectFromProvider && <ImportProjectFromProvider onClose={() => setImportProjectFromProvider(false)}/>}
                </Grid>
            </Grid>
            <List>
                {projects?.map(p =>
                    <ListItem button key={p.id} onClick={() => openProject(p)}>
                        <ProjectCard project={p}/>
                    </ListItem>)}
            </List>
        </Page>
    );
}
