import {EndpointExtraOptions, SimpleResource } from "@rest-hooks/rest";
import {ApiResource} from "./api";
import {Environment} from "./Environment";

export class Deployment extends ApiResource {

    createdAt?: string;
    environment?: string;
    finishedAt?: string;
    source?: string;
    loggingLevel?: string;
    target?: string;
    state?: string;


    /** Get the url for a SimpleResource
     *
     * Default implementation conforms to common REST patterns
     */
    static url<T extends typeof SimpleResource>(this: T, urlParams: Readonly<Record<string, any>>): string {
        return `${Environment.url({id: urlParams.environment})}/deployments/${this.pk(urlParams as any) !== undefined ? this.pk(urlParams as any) : ''}`;
    }

    /** Get the url for many SimpleResources
     *
     * Default implementation conforms to common REST patterns
     */
    static listUrl(searchParams?: Readonly<Record<string, string | number | boolean>>): string {
        return this.url({environment: searchParams?.environment});
    };

    static getEndpointExtra(): EndpointExtraOptions {
        return {
            pollFrequency: 5000, // every 5 seconds
        };
    }

}

export class DeploymentLog extends ApiResource {

    createdAt?: Date;
    sourceType?: string;
    sourceId?: string;
    level?: string;
    content?: string;

    static getEndpointExtra(): EndpointExtraOptions {
        return {
            pollFrequency: 1000, // every 1 seconds
        };
    }

    /** Get the url for a SimpleResource
     *
     * Default implementation conforms to common REST patterns
     */
    static url<T extends typeof SimpleResource>(this: T, urlParams: Readonly<Record<string, any>>): string {
        return `${Deployment.url({id: urlParams.deployment, environment: urlParams.environment})}/logs/${this.pk(urlParams as any) !== undefined ? this.pk(urlParams as any) : ''}`;
    }

    /** Get the url for many SimpleResources
     *
     * Default implementation conforms to common REST patterns
     */
    static listUrl(searchParams?: Readonly<Record<string, string | number | boolean>>): string {
        return this.url({environment: searchParams?.environment, deployment: searchParams?.deployment});
    };


}
