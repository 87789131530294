import {useCallback, useEffect, useState} from "react";
import {User} from "../api/User";

export function useCurrentUser(){
    const [user, setState] = useState<User | null>(null);

    const refresh = useCallback(() => {
        User.getCurrentUser()
            .then(setState);
    }, [setState]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        user,
        refresh
    };
}
