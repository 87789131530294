import {ApiResource} from "./api";
import {Entity} from "@rest-hooks/normalizr";

class Step extends Entity {
    id?: string;
    name?: string;

    pk(parent?: any, key?: string): string | undefined {
        return this.id;
    }

}

export class Workspace extends ApiResource {

    mission?: string;
    environment?: string;
    currentStep?: Step;

    static apiPath = '/v2/workspaces'
}
