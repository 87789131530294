import HeaderBar from "../parts/Header";
import React, {Suspense} from "react";
import {Navigate, RouteObject, Outlet} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {LinearProgress} from "@mui/material";
import {ProjectListPage, ProjectPage, MissionPage} from "./projects";
import {EnvironmentPage} from "./environments";
import {EnvironmentsListPage} from "./environments/EnvironmentsListPage";
import {ProvidersListPage} from "./providers/ProvidersListPage";

function MainLayout() {
    let {keycloak} = useKeycloak();
    if (!keycloak.authenticated) {
        return <LinearProgress/>
    }
    return <div>
        <HeaderBar/>
        <Suspense fallback={<LinearProgress/>}>
            <Outlet/>
        </Suspense>
    </div>;
}

const routes: RouteObject[] = [
    {
        path: "/",
        element: <MainLayout/>,
        children: [
            {
                path: '/',
                element: <Navigate to={"/projects"}/>
            },
            {
                path: '/projects',
                element: <ProjectListPage/>
            },
            {
                path: '/projects/:id',
                element: <ProjectPage/>
            },
            {
                path: '/projects/:id/:tab',
                element: <ProjectPage/>
            },
            {
                path: '/projects/:project/missions/:mission',
                element: <MissionPage/>
            },
            {
                path: '/environments',
                element: <EnvironmentsListPage/>
            },
            {
                path: '/environments/:id',
                element: <EnvironmentPage/>
            },
            {
                path: '/providers',
                element: <ProvidersListPage/>
            },
        ]
    },
    {
        path: '*',
        element: <div>Hello World</div>
    },
];

export default routes;
