import React from "react";
import api, {ApiResource} from "./api";
import {Exclude, plainToClass} from "class-transformer";

export class User extends ApiResource {
    id?: string;
    givenName?: string;
    familyName?: string;
    email?: string;
    username?: string;

    @Exclude({toPlainOnly: true})
    currentUser: boolean = false;

    /* To refactor */
    static async getCurrentUser() {
        const response = await api.get("/v2/users/me");
        let user = plainToClass(User, response.data);
        user.currentUser = true;
        return user;
    }
    /* End to refactor */

    static meUrl() {
        return this.urlWithSubPath('/me')
    }

    static currentUser() {
        return this.memo('#currentUser', () => this.endpoint().extend({
            url: () => this.meUrl()
        }))
    }

    static apiPath = '/v2/users'

}

export const MissionContext = React.createContext<User>(new User());
