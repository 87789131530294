import {ApiResource, smartbotApi} from "./api";

export enum ProviderType {
    GITHUB = "GITHUB",
    GITLAB = "GITLAB",
}

export class Provider extends ApiResource {

    name?: string;
    type?: ProviderType;

    static apiPath = '/v2/providers';

    linkAccount() {
        return `${smartbotApi}/v2/linkProviderAuthentication?providerId=${this.id}`
    }

}
