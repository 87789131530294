import React, {Suspense, useCallback} from "react";
import {Box, Container, LinearProgress, Link} from "@mui/material";
import {NetworkErrorBoundary} from "rest-hooks";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {useNavigate} from "react-router";

type BreadcrumbsLinks = {
    name?: string;
    path?: string;
};

interface PageProps {
    title: React.ReactNode;
    breadcrumbs?: BreadcrumbsLinks[];
}

export const Page: React.FC<PageProps> = (
    {
        title,
        breadcrumbs,
        children
    }
) => {

    const navigate = useNavigate();
    const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        let path = (e.target as HTMLAnchorElement).attributes.getNamedItem('href')?.value;
        if(path && path !== ".") {
            navigate(path);
        }
    }, [navigate]);


    return (
        <Container maxWidth="lg">
            <Box margin={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    {
                        breadcrumbs?.filter(b => b.name !== undefined).map((b) =>
                            <Link key={b.path} color="inherit" href={b.path} onClick={handleClick} underline="hover">
                                {b.name}
                            </Link>
                        )
                    }
                    <Link
                        color="textPrimary"
                        href={'.'}
                        onClick={handleClick}
                        aria-current="page"
                        underline="hover">
                        {title}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box>
                <Suspense fallback={<LinearProgress/>}>
                    <NetworkErrorBoundary>
                        <Box margin={2}>
                            {children}
                        </Box>
                    </NetworkErrorBoundary>
                </Suspense>
            </Box>
        </Container>
    );
}
