import React, {useCallback, useState} from "react";
import {Project} from "../../../api/Project";
import {useCache, useController, useFetcher, useResource} from "rest-hooks";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";
import {ProjectMembership, ProjectRole} from "../../../api/ProjectMembership";
import {useForm} from "react-hook-form";
import {useCurrentUser} from "../../../hooks";
import IconButton from "@mui/material/IconButton";
import {Delete, Edit, PlusOne} from "@mui/icons-material";
import {UserSelect} from "../../../components/form/UserSelect";
import {WithProjectLevelAccess} from "../__components/WithProjectLevelAccess";

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string = "User") {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


function MembershipEdit({
                            project,
                            membership,
                            onClose
                        }: { project: Project, membership?: ProjectMembership, onClose: () => void }) {
    const {handleSubmit, watch, setValue} = useForm({
        defaultValues: {
            userId: membership?.userId,
            level: membership?.level ?? ProjectRole.DEVELOPER
        }
    });
    const {fetch, receive} = useController();

    let handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const previous = useCache(Project.projectMembership(), {projectId: project.id});

    const onSubmit = useCallback(async (data) => {
        fetch(Project.projectMembershipGrant(), {
            projectId: project.id
        }, {
            userId: data.userId,
            level: data.level
        }).then(async (newMembership) => {
            if (!membership && previous) {
                await receive(Project.projectMembership(), {projectId: project.id}, [
                    ...previous,
                    newMembership
                ])
            }
            handleClose();
        })
    }, [fetch, project.id, membership, previous, handleClose, receive])

    return <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <UserSelect sx={{width: "300px", marginBottom: 3}} value={watch('userId')}
                            disabled={!!membership?.membershipId}
                            onChange={(e, u) => setValue('userId', u ?? undefined)}
                            filterUsersId={(id) => !previous || id === membership?.membershipId || !previous.map(p => p.userId).includes(id)}/>
                <FormControl fullWidth>
                    <InputLabel id="level-select">Level</InputLabel>
                    <Select labelId="level-select"
                            label="Level"
                            onChange={e => setValue('level', e.target.value as ProjectRole, {})}
                            value={watch('level')}>
                        <MenuItem value={ProjectRole.OWNER}>OWNER</MenuItem>
                        <MenuItem value={ProjectRole.DEVELOPER}>DEVELOPER</MenuItem>
                        <MenuItem value={ProjectRole.MEMBER}>MEMBER</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </form>
    </Dialog>
}

function MembershipDelete({
                              onClose,
                              membership,
                              project
                          }: { project: Project, membership: ProjectMembership, onClose: () => void }) {
    const deleteMembership = useFetcher(Project.projectMembershipRemove());
    let handleClose = useCallback(() => {
        onClose();
    }, [onClose]);
    const validate = useCallback(() => {
        deleteMembership({projectId: project.id, userId: membership.userId}, membership)
            .then(handleClose);
    }, [deleteMembership, project.id, membership, handleClose])
    return <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Do you want to remove user from Project?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You will remove all access to {membership.name} to project {project.name}.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={validate} autoFocus>
                OK
            </Button>
        </DialogActions>
    </Dialog>
}

function MembershipActions({project, membership}: { project: Project, membership: ProjectMembership }) {
    const {user} = useCurrentUser();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    if (user === undefined || membership.userId === user?.id) {
        return null;
    }
    return <>
        <IconButton aria-label="comments" onClick={() => setEditDialog(true)}>
            <Edit/>
        </IconButton>
        {editDialog && <MembershipEdit project={project} membership={membership} onClose={() => setEditDialog(false)}/>}
        <IconButton aria-label="delete" onClick={() => setDeleteDialog(true)}>
            <Delete/>
        </IconButton>
        {deleteDialog &&
        <MembershipDelete project={project} membership={membership} onClose={() => setDeleteDialog(false)}/>}
    </>;
}

export const MembersList: React.FC<{ project: Project }> = ({project}) => {
    const memberships = useResource(Project.projectMembership(), {projectId: project.id});
    const [addOpen, setAddOpen] = useState(false);
    return (
        <Box marginLeft={-3} marginRight={-3}>
            <List>
                {
                    memberships.map((m) => <ListItem key={m.membershipId}>
                        <ListItemSecondaryAction>
                            <WithProjectLevelAccess level={ProjectRole.OWNER}>
                                <MembershipActions membership={m} project={project}/>
                            </WithProjectLevelAccess>
                        </ListItemSecondaryAction>
                        <ListItemAvatar>
                            <Avatar src={m.avatarUrl} {...stringAvatar(m.name)}/>
                        </ListItemAvatar>
                        <ListItemText primary={
                            <>{m.name}</>
                        }
                                      secondary={
                                          <>{m.level} - {m.email}</>
                                      }/>
                    </ListItem>)
                }
            </List>
            <WithProjectLevelAccess level={ProjectRole.OWNER}>
                <Box sx={{textAlign: "center", marginTop: 3}}>
                    <Button variant="outlined" startIcon={<PlusOne/>} onClick={() => setAddOpen(true)}> Add
                        Member</Button>
                    {addOpen && <MembershipEdit project={project} onClose={() => setAddOpen(false)}/>}
                </Box>
            </WithProjectLevelAccess>
        </Box>
    );
}
