import React, {Suspense} from 'react';
import './App.scss';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import {
    BrowserRouter as Router, useRoutes,
} from "react-router-dom";
import {CacheProvider, NetworkErrorBoundary} from 'rest-hooks';

import keycloak from './keycloak';
import routes from "./pages/routes";
import {LinearProgress} from "@mui/material";

function RoutedApp() {
    return useRoutes(routes);
}

function App() {
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{
                onLoad: 'login-required',
                flow: "standard",
                enableLogging: true,
            }}
            isLoadingCheck={(keycloak) => !keycloak.authenticated}
            onEvent={(event, error) => {
                console.log('onKeycloakEvent', event, error)
            }}
            autoRefreshToken={true}
        >
            <CacheProvider>
                <Suspense fallback={<LinearProgress/>}>
                    <NetworkErrorBoundary>
                        <Router>
                            <RoutedApp/>
                        </Router>
                    </NetworkErrorBoundary>
                </Suspense>
            </CacheProvider>
        </ReactKeycloakProvider>
    );
}

export default App;
