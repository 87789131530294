import {useController, useResource} from "rest-hooks";
import {Page} from "../../components";
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Grid,
    TextField
} from "@mui/material";
import React, {useCallback, useState} from "react";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {Provider} from "../../api/Provider";
import {useForm} from "react-hook-form";


function CreateProvider({
                                       onClose
                                   }: { onClose: () => void }) {
    const {handleSubmit, watch, setValue} = useForm({
        defaultValues: {
            name: "",
            url: "https://gitlab.com",
            clientId: "",
            clientSecret: ""
        }
    });
    const {fetch} = useController();

    let handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const onSubmit = useCallback(async (data) => {
        fetch(Provider.create(), {}, {
            providerEntity: {
                name: data.name,
                type: "GITLAB"
            },
            providerMetas: [
                {
                    key: "url",
                    value: data.url
                },
                {
                    key: "clientId",
                    value: data.clientId
                },
                {
                    key: "clientSecret",
                    value: data.clientSecret
                },
            ]
        }).then(async () => {
            handleClose();
        })
    }, [fetch, handleClose])


    return <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create GitLab Provider</DialogTitle>
            <DialogContent>
                <FormControl sx={{
                    my: 2,
                }} fullWidth>
                    <TextField
                        label={"Name"}
                        value={watch("name")}
                        onChange={e => setValue('name', e.target.value)}
                        required={true}
                    />
                </FormControl>
                <FormControl sx={{
                    my: 2,
                }} fullWidth>
                    <TextField
                        label={"GitLab Server"}
                        value={watch("url")}
                        onChange={e => setValue('url', e.target.value)}
                        required={true}
                    />
                </FormControl>
                <FormControl sx={{
                    my: 2,
                }} fullWidth>
                    <TextField
                        label={"GitLab Client Id"}
                        value={watch("clientId")}
                        onChange={e => setValue('clientId', e.target.value)}
                        required={true}
                    />
                </FormControl>
                <FormControl sx={{
                    my: 2,
                }} fullWidth>
                    <TextField
                        label={"GitLab Client Secret"}
                        value={watch("clientSecret")}
                        onChange={e => setValue('clientSecret', e.target.value)}
                        required={true}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" autoFocus>
                    Create
                </Button>
            </DialogActions>
        </form>
    </Dialog>
}

export const ProvidersListPage = () => {
    const environments = useResource(Provider.list(), {});
    const [createGitLabOpen, setCreateGitLabOpen] = useState(false);

    return <Page title={"Providers"}>
        <Box marginRight={-3} height={450}>
            <Grid container alignItems={"flex-end"} justifyContent={"flex-end"} sx={{mb: 2}}>
                <Grid item>
                    <Button  variant="contained" color="primary" onClick={() => setCreateGitLabOpen(true)}>Create GitLab Provider</Button>
                    {createGitLabOpen && <CreateProvider onClose={() => setCreateGitLabOpen(false)}/>}
                </Grid>
            </Grid>
            <AgGridReact rowData={environments} onRowClicked={({data}) => {
                window.location.href = data.linkAccount();
            }}>
                <AgGridColumn field="name" filter={true}/>
                <AgGridColumn field="type"/>
            </AgGridReact>
        </Box>
    </Page>
}
