import { useResource } from "rest-hooks";
import {Page} from "../../components";
import {Environment} from "../../api/Environment";
import {Box} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

export const EnvironmentsListPage = () => {
    const environments = useResource(Environment.list(), {});
    const navigate = useNavigate();

    return <Page title={"Environments"}>
        <Box marginLeft={-3} marginRight={-3} height={450}>
            <AgGridReact rowData={environments} onRowClicked={({data}) => {
                navigate(`/environments/${data.id}`)
            }}>
                <AgGridColumn field="slug" filter={true}/>
                <AgGridColumn field="kind"/>
                <AgGridColumn field="state"/>
            </AgGridReact>
        </Box>
    </Page>
}
