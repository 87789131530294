import {Mission} from "../../../api/Mission";
import React, {useCallback} from "react";
import {Box, Button, Card, CardActions, CardContent, Chip, Typography} from "@mui/material";
import {useFetcher, useInvalidator, useResource, useSubscription } from "rest-hooks";
import {Workspace} from "../../../api/Workspace";
import {Environment} from "../../../api/Environment";
import {Link} from "react-router-dom";

export const WorkspaceWidget: React.FC<{id: string}> = ({id}) => {
    const workspace = useResource(Workspace.detail(), {id});
    if(workspace.environment == null) throw new Error("Workspace without environment found");
    const environment = useResource(Environment.detailPolling(), {id: workspace.environment});
    const applications = useResource(Environment.applications(), {id: environment.id});
    useSubscription(Environment.detailPolling(), {id: workspace.environment});
    return <Card variant="outlined" sx={{marginTop: 1, marginBottom: 1}}>
        <CardContent>
            <Typography>Environment {environment.slug}</Typography>
            <Typography><Chip size="small" label={environment.state} /></Typography>
        </CardContent>
        <CardActions>
            <Button size="small" component={Link} to={`/environments/${environment.id}`}>Details</Button>
            {
                applications.map((a) => {
                    return <Button size="small" component={"a"} href={a.url} target="__blank">{a.name}</Button>
                })
            }
        </CardActions>
    </Card>
}

export const WorkspacesWidget: React.FC<{mission:Mission}> = ({mission}) => {
    let workspacesIds = mission.workspaces || [];
    const invalidateMission = useInvalidator(Mission.detail());
    const createWorkspace = useFetcher(Workspace.create());
    const create = useCallback(() => {
        createWorkspace({
            mission: mission.id
        }, {}).then(() => {

        }).catch(() => {

        }).finally(() => {
            invalidateMission(mission);
        })
    }, [createWorkspace, invalidateMission, mission])
    return (
        <Box marginY={1}>
            {workspacesIds.map(w => <WorkspaceWidget id={w}/>)}
            <Button variant="contained" fullWidth onClick={create}>Open New Workspace</Button>
        </Box>
    );
}
