import React, {useCallback, useState} from 'react';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {ReactComponent as Logo} from '../logo.svg';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {AccountCircle} from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import {Button, Drawer, List, ListItem, ListItemText, MenuItem} from "@mui/material";
import {useKeycloak} from "@react-keycloak/web";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router";
import { useResource } from 'rest-hooks';
import {User} from "../api/User";

const PREFIX = 'Header';

const classes = {
    appBar: `${PREFIX}-appBar`,
    menuButton: `${PREFIX}-menuButton`,
    title: `${PREFIX}-title`
};

const Root = styled('div')((
    {
        theme
    }: {theme: Theme}
) => ({
    [`& .${classes.title}`]: {
        flexGrow: 1,
        textAlign: 'left',
        '& svg': {
            height: theme.spacing(5),
            width: 'auto'
        }
    }
}));

export default function HeaderBar() {


    const [menuOpen, setOpen] = useState(false);

    const {keycloak} = useKeycloak();
    const auth = keycloak.authenticated;

    const user = useResource(User.currentUser(), {});

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    const navigateTo = useCallback((link: string) => {
        navigate(link);
        setOpen(false);
    }, [navigate]);

    return (
        <Root>
            <Drawer anchor={"left"} open={menuOpen} onClose={() => setOpen(false)}>
                <List>
                    <ListItem button onClick={() => navigateTo(`/projects`)} >
                        <ListItemText primary="Projects"/>
                    </ListItem>
                    <ListItem button onClick={() => navigateTo(`/environments`)} >
                        <ListItemText primary="Environments"/>
                    </ListItem>
                    <ListItem button onClick={() => navigateTo(`/providers`)} >
                        <ListItemText primary="Providers"/>
                    </ListItem>
                </List>
            </Drawer>
            <AppBar position="static" sx={{marginBottom: 2}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        sx={{marginRight: 2}}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setOpen(true)}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <Box component={"div"} className={classes.title}>
                        <Logo/>
                    </Box>
                    {auth && (
                        <div>
                            <Button
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                startIcon={<AccountCircle />}
                            >
                                {user?.givenName} {user?.familyName}
                            </Button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {
                                    handleClose();
                                    keycloak.logout();
                                }}>Log out</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </Root>
    );
}
