import {useNavigate, useParams} from "react-router";
import {useResource} from "rest-hooks";
import {Project, ProjectContext} from "../../api/Project";
import {Page, PageTitle} from "../../components";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, {Suspense, useCallback} from "react";
import {MissionsList} from "./missions";
import {Box, LinearProgress, Button} from "@mui/material";
import {MembersList} from "./members/MembersList";
import api from "../../api/api";

export const ProjectPage = () => {
    const {id: projectId, tab} = useParams<'id' | 'tab'>();
    const navigate = useNavigate();

    const project = useResource(Project.detail(), {id: projectId});

    const openTab = useCallback((e: any, tabName: string) => {
        if(tabName !== tab) {
            navigate(`/projects/${projectId}/${tabName}`);
        }
    }, [tab, navigate, projectId]);

    const downloadCache = useCallback(async () => {
        const response = await api.get(`/v2/projects/${projectId}/cache`);
        if(response && response.data && response.data.url) {
            window.open(response.data.url);
        }
        else {
            alert("Error while getting cache url");
        }
    }, [projectId]);

    return <Page title={project.title} breadcrumbs={[
        {name: 'Projects', path: '/projects'}
    ]}>
        <PageTitle>{project.title}</PageTitle>
        <Button variant={"outlined"}
                            onClick={downloadCache}>Download Cache</Button>
        <Box margin={2}>
            <ProjectContext.Provider value={project}>
                <TabContext value={tab ?? "missions"}>
                    <TabList onChange={openTab}>
                        <Tab label={"Missions"} value={"missions"}/>
                        <Tab label={"Members"} value={"members"}/>
                    </TabList>
                    <Suspense fallback={<LinearProgress/>}>
                        <TabPanel value="missions">
                            <MissionsList project={project}/>
                        </TabPanel>
                        <TabPanel value="members">
                            <MembersList project={project}/>
                        </TabPanel>
                    </Suspense>
                </TabContext>
            </ProjectContext.Provider>
        </Box>
    </Page>
}
