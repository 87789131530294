import React from "react";
import {Project} from "./Project";
import api, {ApiResource} from "./api";
import {classToPlain, plainToClass} from "class-transformer";

export class Mission extends ApiResource{
    id?: string;
    title?: string;
    description?: string;
    workspaces?: string[];
    project?: string;

    /* To refactor */
    static async get(project: Project, missionId: string) {
        const response = await api.get(`/v2/missions/${missionId}`, {params: {projectId: project.id}})
        return plainToClass(Mission, response.data);
    }
    /* End to refactor */

    async save() {
        if(this.id) {
            await api.post(`/v2/missions/${this.id}`)
        } else {
            await api.post(`/v2/missions/`, classToPlain(this));
        }
    }

    static apiPath = "/v2/missions"

}

export const MissionContext = React.createContext<Mission>(new Mission());
