import React from "react";
import {ApiResource} from "./api";
import {Resource, schema} from "@rest-hooks/rest";
import {ProjectMembership} from "./ProjectMembership";
import {ProviderType} from "./Provider";

export class Project extends ApiResource {
    public id?: string;
    public title?: string;
    public updatedAt?: string;
    public name?: string;

    static projectMembership<T extends typeof Resource>(this: T) {
        return this.endpoint().extend({
            url: (urlParams) => {
                return Project.urlWithSubPath(`${urlParams.projectId}/members`);
            },
            schema: [ProjectMembership]
        });
    }

    static projectMembershipGrant<T extends typeof Resource>(this: T) {
        return this.endpointMutate().extend({
            method: 'POST',
            url: (urlParams) => {
                return Project.urlWithSubPath(`${urlParams.projectId}/members`);
            },
            sideEffect: true,
            schema: ProjectMembership
        });
    }

    static projectUserMembership<T extends typeof Resource>(this: T) {
        return this.endpoint().extend({
            url: (urlParams: {projectId: string, userId: string}) => {
                return Project.urlWithSubPath(`${urlParams.projectId}/members/${urlParams.userId}`);
            },
            schema: ProjectMembership
        });
    }

    static projectMembershipRemove<T extends typeof Resource>(this: T) {
        return this.endpointMutate().extend({
            fetch: (params, body) => {
                return this.fetch(Project.urlWithSubPath(`${params.projectId}/members/${params.userId}`), {
                    body, method: 'DELETE'
                }).then(() => (body));
            },
            schema: new schema.Delete(ProjectMembership)
        });
    }

    static importFromProvider<T extends typeof Resource>(this: T) {
        return this.endpointMutate().extend({
            method: "POST",
            url: (params) => {
                if(params.providerType === ProviderType.GITLAB) {
                    return Project.urlWithSubPath(`/import/gitlab`);
                } else {
                    throw Error("Provider hasn't been implemented yet.");
                }
            },
            sideEffect: true,
            schema: Project
        });
    }

    pk(parent?: any, key?: string): string | undefined {
        return this.id?.toString();
    }

    static apiPath = "/v2/projects";

}

export const ProjectContext = React.createContext<Project>(new Project());
