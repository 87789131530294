import {Entity} from "@rest-hooks/normalizr";

export enum ProjectRole {
    /**
     * Basic membership of a project.
     *
     * It grants simple read project view without any action.
     * Any sensitive information is restricted.
     */
    MEMBER = "MEMBER",

    /**
     * Developer for a given project.
     *
     * It grants general actions related to a project.
     * Any sensitive information is restricted.
     */
    DEVELOPER = "DEVELOPER",

    /**
     * Owner membership of a project.
     *
     * It grants ALL write on a project and user can perform any action.
     */
    OWNER = "OWNER"
}

export class ProjectMembership extends Entity {
    public membershipId?: string;
    public userId?: string;
    public level?: ProjectRole;
    public name?: string;
    public email?: string;
    public avatarUrl?: string;

    pk(parent?: any, key?: string): string | undefined {
        return this.membershipId;
    }
}
